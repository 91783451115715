import {
    CAMERA_SELFIE_UPLOAD,
    CAMERA_SELFIE_UPLOAD_SUCCESS,
} from "../../types/camera";

export const defaultState = {
    upload: {},
    request: false,
    completed: false
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case CAMERA_SELFIE_UPLOAD_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                upload: action,
            });
        default:
            return state;
    }
}

export function getUserData(state) {
    const user = state.user;
    return user;
}

