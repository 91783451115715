import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
// import Header from "../layout/header";
// import Drawer from "../layout/drawer";
// import { Redirect } from "react-router-dom";
// import { Tab, Tabs } from 'react-bootstrap'
// import logo from "../../assets/images/logo.png";
// import brand from "../../assets/images/Bgroups.jpg";

class Error extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid px-0 h-100">
                    <div className="content page-notfound">
                      <div className="col-lg-8 col-sm-12 mx-auto">
                        {/* <center><img className="logo_img1" src={logo} /></center> */}
                      </div>
                    </div>

                </div>
            </Fragment >
        )
    }
    static propTypes = {

    };
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);
