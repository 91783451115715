import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from 'prop-types';
import { Field, reduxForm, getFormValues, isValid, autofill, formValues, } from "redux-form";
import validation from "../shared/forms/validation.jsx";
import renderField from "../shared/forms/fieldComponent.jsx";
import * as action from "../../actions/notification";
import vacancy1 from '../../assets/images/VacancyImage1.png';
import vacancy2 from '../../assets/images/VacancyImage2.png';
import * as actions from '../../actions/auth';
import Loader from '../../components/shared/loader';

const required = { required: true };
const formLayout = {
  fields: [
    {
      type: "text",
      name: "name",
      label: "Name",
      validation: {
        required: true,
        length: {
          maximum: 50,
          minimum: 4
        },
      },
    },

    {
      type: "text",
      label: "Email",
      name: "email",
      validation: {
        required: true,
        email: true
      },
    },
    {
      type: "text",
      label: "Phone",
      name: "telNo",

    },
    {
      type: "checkbox",
      label: "checkbox",
      name: "checkbox",
      validation: {
        required: true,
      },
    }
  ],
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      cvName: "",
      coverName: "",
      selectedCvFile: "",
      selectCoverFile: "",
      cvError: false,
      loading: false,
    };
  }
  componentDidMount() {
  }


  onSelectCV = $event => {
    const { formValues, notify } = this.props;
    const size = $event.target.files[0].size / 1048576;
    const type = $event.target.files[0].type;
    if ((type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type == "application/msword") && size < 20) {
      this.setState({ cvName: $event.target.files[0].name });
      const file = $event.target.files[0];
      const myReader = new FileReader();
      const that = this;
      myReader.onloadend = function (loadEvent) {
        setTimeout(() => {
          that.props.formValues["cv"] = loadEvent.target.result;
          that.setState({ cvError: false });
          that.setState({ selectedCvFile: loadEvent.target.result })
        }, 500);
      };

      myReader.readAsDataURL(file);
    }
    else {
      this.setState({ cvError: true });
    }
  }

  onSelectCover = $event => {
    const { formValues, notify } = this.props;
    const size = $event.target.files[0].size / 1048576;
    const type = $event.target.files[0].type;
    if ((type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type == "application/msword") && size < 20) {
      this.setState({ coverName: $event.target.files[0].name });
      const file = $event.target.files[0];
      const myReader = new FileReader();
      const that = this;
      myReader.onloadend = function (loadEvent) {
        setTimeout(() => {
          that.props.formValues["coverLetters"] = loadEvent.target.result
          that.setState({ selectCoverFile: loadEvent.target.result })
        }, 500);
      };

      myReader.readAsDataURL(file);
    }
    else {
      notify("Maximum file size 20Mb and Accept only pdf")
    }

  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { onSubmit, pristine, valid, formValues, notify, campaign } = this.props;
    if (!formValues.cv) {
      this.setState({ cvError: true });
      return false;
    }

    if (!formValues.checkbox) {
      notify("please complete Terms and Conditions");
      return false;
    }
    else {
      this.setState({ cvError: false });
      formValues.cv = this.state.selectedCvFile;

      formValues.coverLetters = this.state.selectCoverFile

      formValues["Campaign"] = campaign.campaignID;
      formValues["Link"] = campaign.link;
      formValues["AccountNo"] = campaign.companyAccount;

      this.props.saveCampaign(formValues)
        .then(a => {
          formValues.ApplicationID = a;
          this.setState({ loading: false });
          if (a > 0) {
            onSubmit(a);
          }
        })
    }
  }

  render() {
    const { onSubmit, pristine, valid, formValues, campaign } = this.props;
    const { showVideo, coverName, cvName, cvError } = this.state;
    if (this.state.loading) {
      return (
        <Loader isLoading={true} />
      );
    }
    return (
      <Fragment>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy1} />
            </div>
            {campaign.vacOpen != 0 && <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3">
              <div className="col-12 heading-company mb-3">
                <h3>Welcome to {campaign && campaign.companyName} <br />
                Application Form for <div className="break-in-mobile">{campaign && campaign.advertTitle}</div></h3>
              </div>
              <div className="col-12 text-compny">
                <p>The application form is in {campaign && campaign.pages} sections.</p>

                <p>In Section 1 please enter your details and upload your CV and a covering letter.</p>
                <p>{campaign && campaign.para2}</p>
                <p>In Section {campaign && campaign.pages} you will take a photo on your webcam from which we extract a psychometric profile which will accompany your application. If you dont have a webcam on your PC please use your phone</p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 section-form">
                <h3>Section 1. Your Details </h3>
                <form name="sectionForm" onSubmit={(e) => this.handleSubmit(e)}>
                  <div className="form-group">
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Full Name*"
                      component={renderField}
                      validation={required}
                      maxLength={50}
                      inputWrapperclassName="form-group"
                    />
                    <Field
                      id="name"
                      name="email"
                      type="text"
                      placeholder="Email*"
                      component={renderField}
                      validation={required}
                      maxLength={50}
                      inputWrapperclassName="form-group"
                    />
                    <Field
                      id="telNo"
                      name="telNo"
                      type="text"
                      placeholder="Phone"
                      component={renderField}
                      inputWrapperclassName="form-group"
                    />
                    <div className={`form-group custom-file mb-3 ${cvError ? "has-error" : ""}`}>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        accept=".pdf,.docx,.doc"
                        onChange={this.onSelectCV} />
                      <label className="custom-file-label" >{cvName ? cvName : "Attach CV File*"}</label>
                      {cvError && <div className="pdfMessage">PDF or Word no more than 20mb</div>}
                    </div>

                    <div className="form-group custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        name="file"
                        accept=".pdf,.docx,.doc"
                        id="customFile"
                        onChange={this.onSelectCover} />
                      <label className="custom-file-label" >{coverName ? coverName : "Attach Cover Letter"}</label>
                    </div>
                    <div className="form-check form-group pl-0 pt-3 pb-3">
                      <Field
                        id="checkbox"
                        name="checkbox"
                        type="checkbox"
                        component={renderField}
                        validation={required}
                        inputWrapperclassName="form-group"
                      />
                      <label className="form-check-label" >I agree with the Terms and Conditions and the Privacy Policy</label>
                    </div>
                    <button disabled={(pristine || isValid) && !valid} type="submit" className="btn btn-next">NEXT</button>
                  </div>
                  <div className="clearfix">&nbsp;</div>
                </form>
              </div>
            </div>
            }

            {campaign.vacOpen == 0 && <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3 pt-3">
              <div className="col-12 heading-company">
                <h3>{campaign && campaign.companyName} <br />
                Application Form for <br />{campaign && campaign.advertTitle}</h3>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 section-form pt-4 pb-5 text-center">
                <h3>Application Full </h3>
              </div>

              <div className="col-lg-8 col-md-12 col-sm-12 mx-auto p-0 selfie-intro text-center">
                <p>We are sorry but we are no longer taking applications for the role of</p>
                <p className="job-title text-center">{campaign && campaign.advertTitle}</p>
              </div>
            </div>
            }
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy2} />
            </div>
          </div>
        </div>
      </Fragment >
    )
  }
  static propTypes = {
    campaign: PropTypes.object,
    saveCampaign: PropTypes.func,
  };
}

const mapStateToProps = state => ({
  formValues: getFormValues("sectionForm")(state),
  campaign: state.user.campaign

});

const mapDispatchToProps = dispatch => ({
  notify: (data) => (dispatch(action.ADD_NOTIFICATION_ERROR(data))),
  saveCampaign: (data) => dispatch(actions.saveCampaign(data))
});

Home = connect(mapStateToProps, mapDispatchToProps)(Home);

export default reduxForm({
  form: "sectionForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validation,
  layout: formLayout,
  enableReinitialize: true,
})(Home);