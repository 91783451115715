import React, { Component, Fragment } from "react";
import { Webcam } from "../../services/webcam";
import { connect } from "react-redux";
import * as actions from "../../actions/camera";
import { ProgressBar } from 'react-bootstrap';
import * as action from '../../actions/auth';
import { PropTypes } from 'prop-types';
import { getFormValues } from "redux-form";
import vacancy1 from '../../assets/images/VacancyImage1.png';
import vacancy2 from '../../assets/images/VacancyImage2.png';
import Loader from '../../components/shared/loader';

let shots = [];
class Camera extends Component {
    constructor(props) {
        super(props);
        this.webcam = null;
        this.state = {
            capturedImage: [],
            captured: false,
            uploading: false,
            GDPRPermission: props.return ? true : false,
            ready: props.return ? true : false,
            cameraNotFound: false
        };
        shots = []
    }

    componentDidMount() {
        if (this.state.ready) {
            this.canvasElement = document.createElement("canvas");
            this.webcam = new Webcam(
                document.getElementById("webcam"),
                this.canvasElement
            );
            this.webcam.setup().then(() => {
                this.setState({ cameraNotFound: false });
            }).catch(() => {
                this.setState({ cameraNotFound: true });
                // alert(
                //     "Error getting access to your camera, open browser in a standalone window"
                // );
            });
        }
    }

    componentWillUnmount() {
        if (this.webcam) {
            this.webcam.stop();
        }
    }

    onChange = () => {
        this.setState({
            GDPRPermission: !this.state.GDPRPermission
        });
    }

    setReady = (e) => {
        e.preventDefault();
        const { onSubmit, pristine, valid, formValues, notify } = this.props;

        this.setState({ ready: true });
        formValues.GDPRPermission = this.state.GDPRPermission;
        this.props.saveCampaign(formValues)
            .then(a => {

            })

        setTimeout(() => {
            this.canvasElement = document.createElement("canvas");
            this.webcam = new Webcam(
                document.getElementById("webcam"),
                this.canvasElement
            );
            this.webcam.setup().then(() => {
                this.setState({ cameraNotFound: false });
            }).catch(() => {
                this.setState({ cameraNotFound: true });
                // alert(
                //     "Error getting access to your camera, open browser in a standalone window"
                // );
            });
        }, 500);
    }

    handleCamera = async () => {
        this.captureImage();
    }

    captureImage = async () => {
        const { onSubmit, setCompleted } = this.props;
        const { uploadToBfm } = this.props;
        const { capturedImage, ip } = this.state;
        this.webcam = new Webcam(
            document.getElementById("webcam"),
            this.canvasElement
        );

        const capturedData = await this.webcam.takeBlobPhoto({
            type: "jpeg",
            quality: 0.8
        });

        shots.push({ image: capturedData });
        this.setState({
            capturedImage: capturedData
        });

        var formData = new FormData();
        formData.append("image", capturedData.blob);
        formData.append("requests", shots.length)

        uploadToBfm(formData);
        if (shots && shots.length === 6) {
            setCompleted();
            return;
        } else {
            setTimeout(() => {
                this.captureImage();
            }, 2000);

        }
    };

    clickReady = (e) => {
        shots = []
        this.captureImage();
        this.props.click()
    }

    render() {
        console.log(this.state)
        const { shape, previousPage, campaign, returnScreen } = this.props;
        const { GDPRPermission, ready, camraScreen } = this.state;

        const buttons = this.state.captured ? (
            <Fragment>
                <button className="btn-next" onClick={this.discardImage}>
                    <span className="hand-cursor-light" ></span>
                    <span className="btn-text">Click Again</span>
                </button>
                <button className="btn-next" onClick={this.submitImage}>
                    <span className="icon-submit" ></span> <span className="btn-text">Submit</span>
                </button>
            </Fragment>
        ) : (
                <div className="col-12 text-center">
                    <button className="btn-next" onClick={this.handleCamera}>
                        <span className="hand-cursor" ></span> <span className="btn-text ">Click</span>
                    </button>
                </div>
            );

        return (
            <Fragment>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
                            <img src={vacancy1} />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3 pt-3">
                            <div className="col-12 heading-company mb-3">
                                <h3>Welcome to {campaign && campaign.companyName} <br />
                Application Form for {campaign && campaign.advertTitle}</h3>
                            </div>
                            <div className={shape === "rectangle" ? "selfie-container-rectangle" : "selfie-container"}>
                                <div className={shape === "rectangle" ? "selfie-rec" : "selfie-avatar"}>
                                    {ready && <video
                                        autoPlay
                                        playsInline
                                        muted
                                        width={250}
                                        height={250}
                                        id="webcam"
                                    />}
                                </div>
                            </div>

                            {!ready && !GDPRPermission &&
                                <div className="selfie-intro">
                                    <p>We are using TR Recruiter to create an accurate  psychometric profile, which will accompany your  application.
                                    Once authorised we will take a short  series of rapid fire photos using your [phone]   /[webcam], to create this profile.
                          </p>
                                    <p>Once your profile has been created your image will be deleted from our files and records.</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <label>I authorise the use of TR Recruiter</label>
                                        <input
                                            type="checkbox"
                                            checked={GDPRPermission}
                                            name="GDPRPermission"
                                            onChange={this.onChange} />
                                    </p>
                                    <div className="col-12 text-center mt-3">
                                        <button className="btn-next" type="button" onClick={previousPage}>
                                            <span className="hand-cursor" ></span> <span className="btn-text ">Back</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            {!ready && GDPRPermission &&
                                <div className="selfie-intro">
                                    <p>We are using TR Recruiter to create an accurate  psychometric profile, which will accompany your application.
                                    Once authorised we will take a short  series of rapid fire photos using your [phone] /[webcam], to create this profile.
                          </p>

                                    <p>Once your profile has been created your image will be deleted from our files and records.</p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <label>I authorise the use of TR Recruiter</label>
                                        <input
                                            type="checkbox"
                                            checked={GDPRPermission}
                                            name="GDPRPermission"
                                            onChange={this.onChange} />
                                    </p>
                                    <div className="col-12 text-center mt-3">
                                        <button className="btn-next" type="button" onClick={this.setReady}>
                                            <span className="hand-cursor" ></span> <span className="btn-text ">Next</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            {ready && GDPRPermission &&
                                <div className="selfie-intro">
                                    <div className="col-12">
                                        {this.props.camraScreen && <div className="col-12 text-center pt-1 progress-bar-psy">
                                            <ProgressBar variant="success" now={(shots.length * 16) + 4} label={`${(shots.length * 16) + 4}%`} />
                                            <p>Please remain still while we process your image</p>
                                        </div>}
                                    </div>
                                    <div className="col-12 psy-list-text list-dashed">
                                        <ul>
                                            <li>Please adopt a password pose</li>
                                            <li>Ensure your head and shoulders fills the lens</li>
                                            <li>Ensure both ears are showing or both edges of your face</li>
                                            <li>Avoid smiling or having your mouth open </li>
                                            <li>Remove any glasses</li>
                                            <li>Do not rotate or tilt your head</li>
                                        </ul>
                                        {!this.props.camraScreen && <p className="text-center pt-3 pb-3">When ready please click the button</p>}
                                    </div>
                                    <div className="col-12 text-center">
                                        {this.props.return && <div className="red mb-3"> We were unable to process your image. Please try again</div>}
                                        {this.state.cameraNotFound && <div className="red mb-3">Cound not find camera or permission has denied. Please allow camera access and refresh the page.</div>}

                                        {!this.props.camraScreen && !this.state.cameraNotFound && <button className="btn-next" onClick={this.clickReady}>
                                            <span className="hand-cursor" ></span> <span className="btn-text ">Ready</span>
                                        </button>}

                                    </div>
                                </div>
                            }

                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
                            <img src={vacancy2} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    static propTypes = {
        campaign: PropTypes.object,
        saveCampaign: PropTypes.func,
        returnScreen: PropTypes.bool,
    };
}

const mapStateToProps = state => ({
    camera: state.camera,
    formValues: getFormValues("sectionForm")(state),
    campaign: state.user.campaign,
    returnScreen: state.user.returnScreen,
});

const mapDispatchToProps = dispatch => ({
    UPLOAD_SELFIE_TO_S3: data => dispatch(actions.uploadToBfm(data)),
    saveCampaign: (data) => dispatch(action.saveCampaign(data)),
    uploadToBfm: data => dispatch(actions.uploadToBfm(data)),
    setCompleted: () => dispatch(actions.setCompleted())
});

export default connect(mapStateToProps, mapDispatchToProps)(Camera);
