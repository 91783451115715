import React, { Component } from "react";
import PropTypes from "prop-types";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { SET_NOTIFICATION_SYSTEM, CLEAR_NOTIFICATIONS } from "../../../actions/notification";

// Stylesheet
import "./notification.css";

class Notification extends Component {
    _notificationSystem = null;

    componentDidMount = () => {
        this._notificationSystem = this.refs.notificationSystem;
        this.props.SET_NOTIFICATION_SYSTEM(this._notificationSystem);
    };

    componentWillUnmount() {
        this.props.CLEAR();
    }

    render() {
        return <NotificationSystem ref="notificationSystem" />;
    }
}

Notification.propTypes = {
    SET_NOTIFICATION_SYSTEM: PropTypes.func,
    CLEAR: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    SET_NOTIFICATION_SYSTEM: notificationSystem => dispatch(SET_NOTIFICATION_SYSTEM(notificationSystem)),
    CLEAR: () => dispatch(CLEAR_NOTIFICATIONS()),
});

export default connect(null, mapDispatchToProps)(Notification);
