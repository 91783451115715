import React, { Component, Fragment, useState } from "react";
import { PropTypes } from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, isValid, autofill, formValues, } from "redux-form";

import validation from "../shared/forms/validation.jsx";
import renderField from "../shared/forms/fieldComponent.jsx";
import vacancy1 from '../../assets/images/VacancyImage1.png';
import vacancy2 from '../../assets/images/VacancyImage2.png';
import Loader from '../../components/shared/loader';

import * as actions from '../../actions/auth';

const formLayout = {
  fields: [
    {
      type: "textarea",
      name: "CampaignQ1Answer",
      label: "Question",
    },
    {
      type: "textarea",
      label: "Question",
      name: "CampaignQ2Answer",
    },
    {
      type: "textarea",
      label: "Question",
      name: "CampaignQ3Answer",
    },
    {
      type: "textarea",
      label: "Question",
      name: "CampaignQ4Answer",
    }
  ],
};


class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { onSubmit, formValues } = this.props;
    this.props.saveCampaign(formValues)
      .then(a => {
        this.setState({ loading: false });
        onSubmit(a);
      })
  }

  render() {
    const { previousPage, pristine, valid, campaign, id } = this.props;
    if (this.state.loading) {
      return (
        <Loader isLoading={true} />
      );
    }
    return (
      <Fragment>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy1} />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3 pt-3">
              <div className="col-12 heading-company">
                <h3>Welcome to {campaign && campaign.companyName}  <br />
                Application Form for {campaign && campaign.advertTitle}</h3>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 section-form pt-4">
                <h3>Section 2. About You </h3>
                <form name="sectionForm" onSubmit={(e) => this.handleSubmit(e)} className="form-group">
                  {campaign && campaign.campaignQuestion1 && <div className="form-group">
                    <label>{campaign && campaign.campaignQuestion1}</label>
                    <Field
                      id="name"
                      name="CampaignQ1Answer"
                      type="textarea"
                      placeholder=""
                      component={renderField}
                      // validation={required}
                      maxLength={250}
                      inputWrapperClass="form-group"
                    />
                  </div>}
                  {campaign && campaign.campaignQuestion2 && <div className="form-group">
                    <label>{campaign && campaign.campaignQuestion2}</label>
                    <Field
                      id="name"
                      name="CampaignQ2Answer"
                      type="textarea"
                      placeholder=""
                      component={renderField}
                      // validation={required}
                      maxLength={250}
                      inputWrapperClass="form-group"
                    />
                  </div>}
                  {campaign && campaign.campaignQuestion3 && <div className="form-group">
                    <label>{campaign && campaign.campaignQuestion3}</label>
                    <Field
                      id="name"
                      name="CampaignQ3Answer"
                      type="textarea"
                      placeholder=""
                      component={renderField}
                      // validation={required}
                      maxLength={250}
                      inputWrapperClass="form-group"
                    />
                  </div>}
                  {campaign && campaign.campaignQuestion4 && <div className="form-group">
                    <label>{campaign && campaign.campaignQuestion4}</label>
                    <Field
                      id="name"
                      name="CampaignQ4Answer"
                      type="textarea"
                      placeholder=""
                      component={renderField}
                      // validation={required}
                      maxLength={250}
                      inputWrapperClass="form-group"
                    />
                  </div>}
                  <div className="col-12 p-0 mt-5">
                    <button disabled={(pristine || isValid) && !valid} type="submit" className="btn btn-next">NEXT</button>
                    <button type="button" onClick={previousPage} className="btn back-btn-form">BACK</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy2} />
            </div>
          </div>
        </div>
      </Fragment >
    )
  }
  static propTypes = {
    campaign: PropTypes.object,
    saveCampaign: PropTypes.func,

  };
}

const mapStateToProps = state => ({
  formValues: getFormValues("sectionForm")(state),
  campaign: state.user.campaign
});

const mapDispatchToProps = dispatch => ({
  saveCampaign: (data) => dispatch(actions.saveCampaign(data))
});

Campaign = connect(mapStateToProps, mapDispatchToProps)(Campaign);

export default reduxForm({
  form: "sectionForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validation,
  layout: formLayout,
  enableReinitialize: true,
})(Campaign);