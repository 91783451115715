import {
    QUESTION_SUCCESS,
    QUESTION_ERROR,
    ANSWER_SUCCESS,
    RESULT_SUCCESS,
    SET_QUESTION_ANSWER,
    QUIZ_SUBMITTED,
    GET_WAIT_TIME_SUCCESS
} from "../../types/generic";

export const defaultState = {
    questions: {},
    answers: [],
    result: {},
    quizSubmitted: false,
    waitTime: {}
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case QUESTION_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                questions: action,
            });
        case ANSWER_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                result: action.result
            });
        case RESULT_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                result: action.result
            });
        case QUESTION_ERROR:
            return Object.assign({}, state, {
                ...state,
            });
        case SET_QUESTION_ANSWER:
            return Object.assign({}, state, {
                ...state,
                answers: [...state.answers, action.answer]
            });
        case QUIZ_SUBMITTED:
            return Object.assign({}, state, {
                ...state,
                quizSubmitted: action.status
            });
        case GET_WAIT_TIME_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                waitTime: action.result
            });
        default:
            return state;
    }
}


export function updateAnswer(state, action) {
    let questionAnswers = [];
    questionAnswers = state.answers || [];

    const answer = [action.answer];
    return questionAnswers.concat(answer);
}


