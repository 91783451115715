import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import * as notificationConstants from "../../types/generic"

let notificationSystem;

export default function notificationReducer(state = {}, action) {
    switch (action.type) {
        case notificationConstants.NOTIFICATION_SET_NOTIFICATION_SYSTEM:
            notificationSystem = action.notificationSystem;
            return state;
        case notificationConstants.NOTIFICATION_ADD:
            if (notificationSystem) {
                notificationSystem.addNotification(action.notification);
                if (notificationSystem.state.notifications.length > 8) {
                    const notification = {
                        title: "Notification",
                        level: "info",
                        position: "tl",
                        autoDismiss: 20,
                        uid: "clear_all",
                        children: (
                            <div className="notification-action-wrapper">
                                <ButtonToolbar>
                                    <Button
                                        className="notification-button notification-button-white"
                                        onClick={() => notificationSystem.removeNotification("clear_all")}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        className="notification-button notification-button-blue"
                                        bsStyle="primary"
                                        onClick={() => notificationSystem.clearNotifications()}
                                    >
                                        Yes
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        ),
                    };

                    notificationSystem.addNotification(notification);
                }
            }
            return state;
        case notificationConstants.NOTIFICATION_REMOVE:
            if (notificationSystem) {
                notificationSystem.removeNotification(action.notification);
            }
            return state;
        case notificationConstants.NOTIFICATION_CLEAR_NOTIFICATIONS:
            if (notificationSystem) {
                notificationSystem.clearNotifications();
            }
            return state;
        default:
            return state;
    }
}
