export const QUESTION_SUCCESS = 'QUESTION_SUCCESS';
export const ANSWER_SUCCESS = 'ANSWER_SUCCESS';
export const RESULT_SUCCESS = 'RESULT_SUCCESS';
export const QUESTION_ERROR = 'QUESTION_ERROR';
export const SET_QUESTION_ANSWER = "SET_QUESTION_ANSWER";
export const QUIZ_SUBMITTED = "QUIZ_SUBMITTED";
export const GET_WAIT_TIME_SUCCESS = "GET_WAIT_TIME_SUCCESS";

export const NOTIFICATION_SET_NOTIFICATION_SYSTEM = "NOTIFICATION_SET_NOTIFICATION_SYSTEM";
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE";
export const NOTIFICATION_CLEAR_NOTIFICATIONS = "NOTIFICATION_CLEAR_NOTIFICATIONS";