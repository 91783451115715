import React, { Component, Fragment, useState } from "react";
import { PropTypes } from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, isValid, autofill } from "redux-form";
import vacancy1 from '../../assets/images/VacancyImage1.png';
import vacancy2 from '../../assets/images/VacancyImage2.png';

class Complete extends Component {

  render() {
    const { campaign, userData } = this.props;
    const getName=()=>{
      if(!userData && userData.name){
        return "";
      }
      return userData.name.split(' ')[0];
    }
    return (
      <Fragment>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy1} />
            </div>

            <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3 pt-3">
              <div className="col-12 heading-company">
                <h3>Application Complete {campaign && campaign.companyName} <br />
                Application Form for {campaign && campaign.advertTitle}</h3>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 section-form pt-4 pb-5 text-center">
                <h3>Application Complete </h3>
              </div>

              <div className="col-lg-8 col-md-12 col-sm-12 mx-auto p-0 selfie-intro">
                <p>Thank you {getName()} for your application for the role of {campaign && campaign.advertTitle}.</p>
                <p>Your details have been uploaded and we will get in touch in the event that we
wish to take your application further.</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
              <img src={vacancy2} />
            </div>
          </div>
        </div>

      </Fragment >
    )
  }
  static propTypes = {
    campaign: PropTypes.object,
    userData: PropTypes.object
  };
}

const mapStateToProps = state => ({
  campaign: state.user.campaign,
  userData: state.form.sectionForm.values
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Complete);