import React, { Component } from "react";
import { BrowserRouter, Router, Route, IndexRoute, Switch, IndexRedirect, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { appLoad } from "./actions/auth";
import Notification from "./components/shared/notification";
import Error from "./components/error";
import Campaign from "./components/campaign";
import Complete from "./components/complete";
import DashboardWizard from "./components/dashboard";
import Camera2 from "./components/camera2";

class RouterConfig extends Component {
    UNSAFE_componentWillMount() {
        const { appLoad } = this.props;
        this.loading = appLoad();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.loading = Promise.resolve(nextProps.user);
    }

    shouldComponentUpdate() {
        // Router is always static
        return false;
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route component={DashboardWizard} path="/org/:organizationID" />
                    <Route component={Campaign} path="/campaign" />
                    <Route component={Camera2} path="/camera2" />
                    <Route component={Complete} path="/completed" />
                    <Route component={Error} path="" />
                </Switch>
                <Notification />
            </BrowserRouter>
        );
    }
}

RouterConfig.propTypes = {
    user: PropTypes.object,
    appLoad: PropTypes.func,
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = {
    appLoad,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RouterConfig);