import * as notificationsAction from '../../actions/notification';
import { getCookie } from '../../services/cookie';
import {
    CAMERA_SELFIE_UPLOAD,
    CAMERA_SELFIE_UPLOAD_SUCCESS,
    CAMPAIGN_COMPLETED,
    RETURN_SELFIE,
    THANK_YOU
} from '../../types/camera';
import {
    lOADERSTART,
} from '../../types/shared/loader';
import * as api from "../../services/api";


export function loaderRequest(req) {
    return {
        type: lOADERSTART,
        value: req,
    };
}

export function selfie_Uploaded(result) {
    return {
        type: CAMERA_SELFIE_UPLOAD_SUCCESS,
        ...result,
    };
}

export function setCompleted() {
    return {
        type: CAMPAIGN_COMPLETED,
        status: true
    };
}

export function returnSelfieScreen(value) {
    return {
        type: RETURN_SELFIE,
        returnScreen: value,
    };
}

export function thankYouScreen(value) {
    return {
        type: THANK_YOU,
        thankYouScreen: value,


    };
}

export function uploadToBfm(data) {
    return dispatch => {
        api.formPost("photo", data)
            .then((response) => {
                console.log(response, "response");
                if (response && response.data && response.data.faces) {
                    dispatch(selfie_Uploaded(response.data));
                    dispatch(getPycReport(response.data, Number(data.get('requests'))));
                } else {
                    //dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('SomeThing Went Wrong.'));
                }
            })
            .catch((error) => {
                if (Number(data.get('requests')) === 6) {
                    dispatch(returnSelfieScreen(true));
                }
                //dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
            });
    };
}

export function getPycReport(data, count) {
    return dispatch => {
        if (!data || !data.faces || !data.faces[0].person) {
            return;
        }
        api.fetch(`psy/${data.faces[0].person.true_id}`)
            .then((response) => {
                dispatch(saveApiResponse(response.data, data, count));
            })
            .catch((error) => {
                // dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
            });
    };
}

export function saveApiResponse(psyResult, bfmData, count) {
    return (dispatch, getStore) => {
        if (!psyResult || !psyResult.psy_groups || !bfmData || !bfmData.faces) {
            return;
        }

        var ip = JSON.parse(getCookie('ip'));
        const state = getStore();
        psyResult['testId'] = state.form.sectionForm.values.ApplicationID;
        psyResult['campaignID'] = state.form.sectionForm.values.ApplicationID;
        psyResult['true_id'] = bfmData.faces[0].person.true_id;
        psyResult['Link'] = state.form.sectionForm.values.organizationID;
        psyResult['IPAddress'] = ip.ip;

        api.savePsyData('user/SavePsyResult', escapeJson(JSON.stringify(psyResult)))
            .then((response) => {
                if (response && response.status == 200 && count === 6) {
                    dispatch(executeReport())
                } else {
                    //dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('SomeThing Went Wrong.'));
                }
            })
            .catch((error) => {
                // dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
            });
    };
}

export function executeReport() {
    return (dispatch, getStore) => {
        const state = getStore();
        const ApplicationID = state.form.sectionForm.values.ApplicationID;

        api.postAsJson('user/ExecuteReport', Number(ApplicationID))
            .then((response) => {
                if (response && response.data == 0 && response.status == 200) {
                    dispatch(returnSelfieScreen(true));
                } else if (response && response.data != 0 && response.status == 200) {
                    dispatch(thankYouScreen(true));
                    //dispatch(notificationsAction.ADD_NOTIFICATION_SUCCESS('Your report has been saved successfully.'));
                }
            })
            .catch((error) => {
                // dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
            });
    };
}

export function escapeJson(json) {
    var escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
    var meta = {    // table of character substitutions
        '\b': '\\b',
        '\t': '\\t',
        '\n': '\\n',
        '\f': '\\f',
        '\r': '\\r',
        '"': '\\"',
        '\\': '\\\\'
    };

    escapable.lastIndex = 0;
    return escapable.test(json) ? '"' + json.replace(escapable, function (a) {
        var c = meta[a];
        return (typeof c === 'string') ? c
            : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
    }) + '"' : '"' + json + '"';
};