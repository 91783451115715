
import { lOADERSTART, lOADEREND } from "../../../types/shared/loader";


export const defaultState = {
    showLoader: false
};

export default function loaderReducer(state = defaultState, action) {
    switch (action.type) {
        // These cases handle the process of logging in with auth0
        case lOADERSTART:
            return {
                ...state,
                showLoader: action.value
            };

        case lOADEREND:
            return {
                ...state,
                showLoader: false
            }
        default:
            return state;
    }
}


// export const getUser = createSelectorCreator(defaultMemoize, isEqual)(state => state.user, user => user);

// export function getUserData(state) {
//     const user = state.user;
//     return user;
// }
