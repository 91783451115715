import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./reducers";
import RouterConfig from "./routes";
import "./assets/vendor/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/style.css";

ReactDOM.render(
  <Provider store={store}>
      <RouterConfig />
  </Provider>,
  document.getElementById("app")
);
