import React from 'react';
import { createDevTools } from 'redux-devtools'
import LogMonitor from 'redux-devtools-log-monitor'
import DockMonitor from 'redux-devtools-dock-monitor'
import { createStore, combineReducers, applyMiddleware,compose } from "redux";
import { reducer as form } from "redux-form";
import user from "./user";
import camera from "./camera";
import chat from "./chat";
import quiz from "./quiz";
import notification from "./notification";
import loader from "./shared/loader";

import thunkMiddleware from 'redux-thunk'

const reducer = combineReducers({
    user,
    camera,
    chat,
    quiz,
    notification,
    loader,
    form
})

const DevTools = createDevTools(
    <DockMonitor toggleVisibilityKey="ctrl-h"
        changePositionKey="ctrl-q">
        <LogMonitor theme="tomorrow" />
    </DockMonitor>
)

 
const middleware = applyMiddleware(thunkMiddleware);

// Note: passing enhancer as the last argument requires redux@>=3.1.0
const store = createStore(
    reducer,
    compose(middleware, window.devToolsExtension ? window.devToolsExtension() : f => f)
)

export default store;
