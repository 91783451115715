import * as notificationsAction from '../../actions/notification';
import { getCookie, setCookie, deleteCookie, loadTokenFromCookies } from "../../services/cookie";
import fetch from 'node-fetch';
import {
    USER_LOGIN_SUCCESS,
    USER_CAMPAIGN_SUCCESS
} from '../../types/user';
import {
    lOADERSTART,
} from '../../types/shared/loader';
import * as api from "../../services/api";

export function loginSuccess(authResult) {
    console.log(authResult);
    return {
        type: USER_LOGIN_SUCCESS,
        access_token: authResult,
    };
}

export function campaignSuccess(campaign) {
    return {
        type: USER_CAMPAIGN_SUCCESS,
        ...campaign,
    };
}

export function loaderRequest(req) {
    return {
        type: lOADERSTART,
        value: req,
    };
}

export function appLoad() {
    return dispatch => {
        // const authToken = getCookie("access_token");
        // if (authToken) {
        //     //dispatch(loginSuccess(JSON.parse(authToken)));
        // } else {
        dispatch(fitMeAuthorization());
        //}
    }
}

export function fitMeAuthorization() {
    return dispatch => {
        dispatch(loaderRequest(true));
        deleteCookie("access_token");
        api.fetchWithoutHeader(`user/usertoken/`)
            .then((response) => {
                //console.log(response, "response");
                if (!response || !response.data || (response.status !== 200)) {
                    dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Invalid credentials.'));
                    dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error authorizing, please try again later.'));
                    dispatch(loaderRequest(false));
                    return;
                }
                const authToken = JSON.parse(response.data);
                setCookie("access_token", authToken.access_token);
                //dispatch(loginSuccess(authToken.access_token));
                dispatch(loaderRequest(false));
            })
            .catch((error) => {
                dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error authorizing, please try again later.'));
                dispatch(loaderRequest(false));
            });
    };
}

export function getCampaign(id) {
    return dispatch => {
        dispatch(loaderRequest(true));
        api.fetchWithoutHeader(`user/GetCompanyInfo/${id}`)
            .then((response) => {
                //console.log(response, "response");
                if (!response || !response.data || (response.status !== 200)) {
                    dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Invalid credentials.'));
                    dispatch(loaderRequest(false));
                    return;
                }
                setCookie("campaignInfo", JSON.stringify(response.data));
                dispatch(campaignSuccess(response.data));
                dispatch(loaderRequest(false));
            })
            .catch((error) => {
                dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
                dispatch(loaderRequest(false));
            });
    };
}

export function getIp() {
    return dispatch => {
        return api.ip(`//api.ipify.org/?format=json`)
            .then((response) => {
                //console.log(response, "response");
                setCookie("ip", JSON.stringify(response.data));
                dispatch(loaderRequest(false));
            })
            .catch((error) => {

            });
    };
}

export function saveCampaign(item) {
    return dispatch => {
        return api.storeWithouHeader(`user/SaveCampaign`, item)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                dispatch(notificationsAction.ADD_NOTIFICATION_ERROR('Error while processing request.'));
            });
    };
}
