import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { PropTypes, bool } from 'prop-types';
import Home from "../home";
import Campaign from "../campaign";
import Camera2 from "../camera2";
import Complete from "../complete";


import Loader from '../../components/shared/loader';
import * as action from '../../actions/auth';
import * as actions from '../../actions/camera';
import {
    autofill,
} from "redux-form";


class DashboardWizard extends Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: 1,
            id: 0,
            returnValue: false,
            camraScreen: false
        };
    }
    componentDidMount() {
        const { match, fillForm, getIp } = this.props;
        this.props.getIp();
        localStorage.setItem('organizationID', match.params.organizationID);
        this.props.getCampaignInfo(match.params.organizationID);
        fillForm(match.params.organizationID, 0)
    }
    skip() {
        const { loading, campaign } = this.props;
        this.setState({ page: this.state.page + 2 });

    }
    nextPage(a) {

        const { loading, campaign } = this.props;
        if (!campaign.campaignQuest && this.state.page == 1) {
            this.skip();
            return
        }
        this.setState({ page: this.state.page + 1, id: a > 0 ? a : 0 });
    }

    previousPage() {

        const { loading, campaign, } = this.props;
        if (!campaign.campaignQuest && this.state.page == 3) {
            this.setState({ page: this.state.page - 2 });
            return
        }
        this.setState({ page: this.state.page - 1 });
    }

    camraScreen = () => { this.setState({ camraScreen: true }) }

    render() {
        const { page, id, returnValue, camraScreen } = this.state;
        const { loading, campaign, returnScreen, message, returnSelfieScreen, thankYouScreen, thankYou } = this.props;
        if (returnScreen) {

            this.setState({ page: 3, returnValue: true, camraScreen: false })
            returnSelfieScreen();
        }
        if (thankYou) {
            this.setState({ page: 4 });
            thankYouScreen();

        }

        if (loading) {
            return (
                <Loader isLoading={loading} />
            );
        }

        return (
            <Fragment>
                {page === 1 && <Home onSubmit={this.nextPage} />}
                {page === 2 &&
                    <Campaign id={id}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />}
                {page === 3 &&
                    <Camera2 return={returnValue}
                        camraScreen={camraScreen}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                        click={this.camraScreen}
                    />}
                {page === 4 &&
                    <Complete campaign={this.props.campaign}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />}
            </Fragment >
        )
    }
    static propTypes = {
        getCampaignInfo: PropTypes.func,
        campaign: PropTypes.object,
        loading: PropTypes.bool,
        returnScreen: PropTypes.bool,
        thankYou: PropTypes.bool,
        returnSelfieScreen: PropTypes.func,
        thankYouScreen: PropTypes.func,
        getIp: PropTypes.func,
    };
}

const mapStateToProps = state => ({
    loading: state.loader.showLoader,
    campaign: state.user.campaign,
    returnScreen: state.user.returnScreen,
    thankYou: state.user.thankYou
});

const mapDispatchToProps = dispatch => ({
    fillForm: (organizationID, ApplicationID) => {
        dispatch(autofill("sectionForm", "organizationID", organizationID));
        dispatch(autofill("sectionForm", "ApplicationID", ApplicationID));
    },
    getCampaignInfo: (id) => dispatch(action.getCampaign(id)),
    getIp: () => dispatch(action.getIp()),
    returnSelfieScreen: () => dispatch(actions.returnSelfieScreen(false)),
    thankYouScreen: () => dispatch(actions.thankYouScreen(false))

});

DashboardWizard = connect(mapStateToProps, mapDispatchToProps)(DashboardWizard);

export default (DashboardWizard);