import React from "react";

import * as notificationConstants from "../../types/generic";

export function SET_NOTIFICATION_SYSTEM(notificationSystem) {
    return {
        type: notificationConstants.NOTIFICATION_SET_NOTIFICATION_SYSTEM,
        notificationSystem,
    };
}

export function ADD_NOTIFICATION(notification) {
    return {
        type: notificationConstants.NOTIFICATION_ADD,
        notification,
    };
}

export function REMOVE_NOTIFICATION(notification) {
    return {
        type: notificationConstants.NOTIFICATION_REMOVE,
        notification,
    };
}

export function CLEAR_NOTIFICATIONS() {
    return {
        type: notificationConstants.NOTIFICATION_CLEAR_NOTIFICATIONS,
    };
}

export function ADD_NOTIFICATION_ERROR(message) {
    const notification =  {
        title: "Error",
        message: message,
        level: "error",
        autoDismiss: 300,
        dismissible: true,
        action: {
            label: "OK",
        },
    };

    return {
        type: notificationConstants.NOTIFICATION_ADD,
        notification,
    };
}

export function ADD_NOTIFICATION_SUCCESS(message) {
    const notification = {
        title: "Success",
        message: message,
        level: "success",
        autoDismiss: 5,
    };

    return {
        type: notificationConstants.NOTIFICATION_ADD,
        notification,
    };
}

export function addNotificationWarning({ message, messageValues, title = "Generic.Warning", titleValues }) {
    const notification = {
        title: title,
        message: message,
        level: "warning",
        autoDismiss: 20,
    };

    return {
        type: notificationConstants.NOTIFICATION_ADD,
        notification,
    };
}
