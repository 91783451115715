import {
    USER_LOGIN_SUCCESS,
    USER_LOGIN_ERROR,
    USER_LOGOUT,
    USER_CAMPAIGN_SUCCESS,
} from "../../types/user";
import { CAMPAIGN_COMPLETED ,
    RETURN_SELFIE,
    THANK_YOU
} from "../../types/camera";
export const defaultState = {
    user: {},
    request: false,
    campaign: {},
    returnScreen:false,
    message:"",
    thankYou:false,
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            console.log(action.access_token);
            return Object.assign({}, state, {
                ...state,
                user: action.access_token,
            });
        case USER_LOGIN_ERROR:
            return Object.assign({}, state, {
                auth0ModalError: action.error,
            });
        case USER_CAMPAIGN_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                campaign: action
            });
        case CAMPAIGN_COMPLETED:
            return Object.assign({}, state, {
                ...state,
                completed: action.status
            });

            case RETURN_SELFIE:
                return Object.assign({}, state, {
                    ...state,
                    returnScreen: action.returnScreen,
                });
                case THANK_YOU:
                    return Object.assign({}, state, {
                        ...state,
                        thankYou: action.thankYouScreen,
                        
                    });
        case USER_LOGOUT:
            return defaultState;
        default:
            return state;
    }
}

export function getUserData(state) {
    const user = state.user;
    return user;
}

