import {
    OTP_SENT,
    OTP_VERIFICATION,
    CHAT_MESSAGE_RECIVED,
    CHAT_MESSAGE_ERROR
} from '../../types/chat';

export const defaultState = {
    messages: [],
    otpResponse: null,
    request: false,
    pushArray: [{ "inputmessage": '', "apivalue": 'I am Chatbot. I will help.' },
    { "inputmessage": '', "apivalue": 'Please Type Your Mobile Number.' }]
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case OTP_SENT:
            return Object.assign({}, state, {
                ...state,
                pushArray: [...state.pushArray, action],
            });
        case OTP_VERIFICATION:
            return Object.assign({}, state, {
                ...state,
                messages: action,
            });
        case CHAT_MESSAGE_RECIVED:
            return Object.assign({}, state, {
                ...state,
                messages: [...state.messages, action],
            });
        case CHAT_MESSAGE_ERROR:
            return Object.assign({}, state, {
                ...state,
                messages: action,
            });
        default:
            return state;
    }
}

export function getUserData(state) {
    const user = state.user;
    return user;
}

