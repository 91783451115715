import axios from 'axios';
import * as utils from './cookie';
import { API_CONFIG } from '../config';

export function fetch(pathParam) {
    return axios
        .get(API_CONFIG.bfmApi + pathParam, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } });
}
export function store(pathParam, data) {
    console.log("API_CONFIG.baseUrl:: ", API_CONFIG.baseUrl);
    return axios
        .post(API_CONFIG.baseUrl + pathParam, data, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } });
}

export function update(pathParam, data) {
    return axios
        .put(API_CONFIG.baseUrl + pathParam, data, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } });
}

export function patch(pathParam, data) {
    return axios
        .patch(API_CONFIG.baseUrl + pathParam, data, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } });
}

export function getWithParem(pathParam, data) {
    return axios.get(API_CONFIG.baseUrl + pathParam, {
        params: data
    }, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } })
}

export function destroy(pathParam) {
    return axios
        .delete(API_CONFIG.baseUrl + pathParam, { headers: { 'Authorization': `Bearer ${utils.getCookie('access_token')}` } });
}


export function storeWithouHeader(pathParam, data) {
    return axios
        .post(API_CONFIG.baseUrl + pathParam, data);
}

export function fetchWithoutHeader(pathParam) {
    return axios
        .get(API_CONFIG.baseUrl + pathParam);
}

export function fileUpload(url, formValue) {
    var options = {
        headers: {
            'Content-Type': "multipart/form-data",
            'Authorization': `Bearer ${utils.getCookie('idToken')}`,
        }
    };

    return axios
        .put(API_CONFIG.baseUrl + url, formValue, options);
}

export function formPost(url, formValue, headers) {
    var headers = {
        'Content-Type': "multipart/form-data",
        'Authorization': `Bearer ${utils.getCookie('access_token')}`,
    }

    return axios
        .post(`${API_CONFIG.bfmApi}${url}`, formValue, { headers });
}

export function savePsyData(url, formValue, headers) {
    var headers = {
        'Content-Type': "application/json",
    }

    return axios
        .post(`${API_CONFIG.baseUrl}${url}`, formValue, { headers });
}


export function postAsJson(url, formValue, headers) {
    var headers = {
        'Content-Type': "application/json",
    }

    return axios
        .post(`${API_CONFIG.baseUrl}${url}`, formValue, { headers });
}

export function ip(url) {
    var headers = {
        'Content-Type': "application/json",
//         'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
// 'access-control-allow-origin': '*'
     
    }
    return axios
        .get(`${url}`,{headers});
}